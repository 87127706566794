import React from 'react';
import { InfoCard, CodeSnippet } from '@backstage/core-components';

import { useRFStore, RFState } from './store';

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  setRoadmap: state.setRoadmap,
});

export const CodeViewer = () => {
  const { nodes, edges } = useRFStore(selector);

  const code = {
    nodes: nodes,
    edges: edges,
  };

  return (
    <InfoCard title="Copy Code">
      <CodeSnippet
        text={JSON.stringify(code, undefined, 4)}
        language="javascript"
        showCopyCodeButton
      />
    </InfoCard>
  );
};
