import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRoadmapRouteRef = createRouteRef({
  id: 'techdoc-roadmaps-and-goldenpath',
});

export const rootRoadmapEditorRouteRef = createRouteRef({
  id: 'techdoc-roadmaps-and-goldenpath-editor',
});

export const entityRoadmapEditorRouteRef = createRouteRef({
  id: 'techdoc-roadmaps-and-goldenpath-entity-editor',
  params: ['namespace', 'kind', 'name'],
});

export const entityRoadmapViewerRouteRef = createRouteRef({
  id: 'techdoc-roadmaps-and-goldenpath-entity-viewer',
  params: ['namespace', 'kind', 'name'],
});
