import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    secondaryNode: {
      color: 'black',
      minHeight: '40px',
      minWidth: '150px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      borderWidth: '2px',
      fontSize: '12px',
      fontWeight: 700,
      backgroundColor: 'orange',
      border: '1px solid black',
      padding: '2px',
      textAlign: 'center',
    },
    recommended: {
      border: 'solid 2px black',
      color: 'black',
      position: 'relative',
      '&::before, &::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        width: '20px',
        height: '20px',
        backgroundImage:
          'url(http://www.clker.com/cliparts/p/e/X/9/d/4/check-mark-white-md.png), linear-gradient(135deg, green 50%, transparent 50%)',
        backgroundSize: '70% auto, cover',
        backgroundPosition: 'auto, left top',
        backgroundRepeat: 'no-repeat',
      },
      '&::after': {
        zIndex: -10,
      },
    },
  }),
);

const SecondaryNode = ({ data, isConnectable }: NodeProps) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.secondaryNode} ${data.recommend ? classes.recommended : ''}`}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      {data?.label}
      <Handle
        type="target"
        id="r"
        position={Position.Right}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        id="t"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        id="b"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </div>
  );
};

SecondaryNode.displayName = 'SecondaryNode';

export default memo(SecondaryNode);
