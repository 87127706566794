import React from 'react';
import {
  EFGCustomTechDocsHome,
  TabsConfig,
  PanelConfig,
} from '../techdocs/EFGCustomTechDocsHome';

function generateCardGridPanel(
  title: string,
  tags: string[],
  owner?: string,
  kind?: string,
): PanelConfig {
  return {
    title: title,
    description: '',
    panelType: 'DocsCardGrid',
    filterPredicate: entity =>
      tags.some(tag => entity?.metadata?.tags?.includes(tag)) &&
      (owner ? entity?.spec?.owner === owner : true) &&
      (kind ? entity?.kind === kind : true),
  };
}

const technologyAtEfgTabsConfig: TabsConfig = [
  {
    title: 'Technology',
    path: 'technology',
    panels: [
      generateCardGridPanel('Technology', ['technology-docs']),
      generateCardGridPanel('Guides', ['technology-guides']),
    ],
  },
  {
    title: 'Frontend',
    path: 'frontend',
    panels: [
      generateCardGridPanel('Frontend', ['frontend-docs']),
      generateCardGridPanel('FACEIT Monorepo', ['faceit-frontend-monorepo']),
    ],
  },
  {
    title: 'Backend',
    path: 'backend',
    panels: [generateCardGridPanel('Backend', ['backend-docs'])],
  },
  {
    title: 'Infrastructure',
    path: 'infrastructure',
    panels: [
      // the tech-at-efg label is used so we can filter out some domains we don't want to show up
      generateCardGridPanel(
        'Cloud & Game Servers',
        ['tech-at-efg'],
        'group:team/cloudgs',
        'Domain',
      ),
      generateCardGridPanel(
        'DevXP',
        ['tech-at-efg'],
        'group:team/devxp',
        'Domain',
      ),
      generateCardGridPanel('SRE', ['tech-at-efg'], 'group:team/sre', 'Domain'),
    ],
  },
  {
    title: 'Teams',
    path: 'teams',
    panels: [generateCardGridPanel('Team documentation', ['team-docs'])],
  },
];

export const TechnologyAtEFG = () => {
  return (
    <EFGCustomTechDocsHome
      title="Technology At EFG"
      tabsConfig={technologyAtEfgTabsConfig}
    />
  );
};
