import {
  isJenkinsAvailable,
  EntityLatestJenkinsRunCard,
} from '@backstage-community/plugin-jenkins';
import { EntitySwitch } from '@backstage/plugin-catalog';
import {
  isAnimalsProjectIDAnnotationAvailable,
  EntityAnimalsDeployerCard,
} from '@efg/plugin-animals-deploy';
import { Grid } from '@material-ui/core';
import React from 'react';
import { AboutCard as EntityAboutCard } from '../../pages/AboutCard';
import { CortexScorecardWidget } from '../../../cortex';
import { entityWarningContent } from './EntityWarningContent';
import { EntityLinks } from './EntityLinksContent';

export const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item xs={12}>
      <EntityLinks />
    </Grid>
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isAnimalsProjectIDAnnotationAvailable}>
        <Grid item sm={6}>
          <EntityAnimalsDeployerCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isJenkinsAvailable}>
        <Grid item sm={6}>
          <EntityLatestJenkinsRunCard branch="main,master" variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <Grid item xs={6}>
      <CortexScorecardWidget />
    </Grid>
  </Grid>
);
