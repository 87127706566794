import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { costMetricsApiRef, KubeCostMetricsApi } from './apis/costMetricsApi';

/**
 * costMetricsApiFactory provide a default way to instantiate the api client for that plugin
 */
export const costMetricsApiFactory = createApiFactory({
  api: costMetricsApiRef,
  deps: {
    fetchApi: fetchApiRef,
    discoveryApi: discoveryApiRef,
  },
  factory: ({ fetchApi, discoveryApi }) =>
    new KubeCostMetricsApi({
      fetchApi: fetchApi,
      discoveryApi: discoveryApi,
    }),
});

export const costMetricsPlugin = createPlugin({
  id: 'cost-metrics',
  apis: [costMetricsApiFactory],
  routes: {
    root: rootRouteRef,
  },
});

export const CostMetricsPage = costMetricsPlugin.provide(
  createRoutableExtension({
    name: 'CostMetricsPage',
    component: () =>
      import('./components/CostMetricsOverviewPage').then(
        m => m.CostMetricsOverviewPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const CostMetricsGroupCard = costMetricsPlugin.provide(
  createComponentExtension({
    name: 'CostMetricsGroupCard',
    component: {
      lazy: () =>
        import('./components/CostMetricsGroupCard').then(
          m => m.CostMetricsGroupCard,
        ),
    },
  }),
);
