import React from 'react';
import {
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { useDocDialogStore, DocDialogState } from './store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

export default {
  title: 'Layout/Dialog',
  component: Dialog,
};

const selectorDialog = (state: DocDialogState) => ({
  open: state.open,
  node: state.node,
  setNode: state.setNode,
  openDialog: state.openDialog,
  closeDialog: state.closeDialog,
});

export const DocCreationActionDialog = () => {
  const classes = useStyles();
  const { open, closeDialog } = useDocDialogStore(selectorDialog);

  const dialogContent = () => {
    return (
      <>
        <Typography>
          Creating New Documentation page is NOT Automated Yet!!!!
        </Typography>
        <Typography>Go to Code your repository. Then:</Typography>
        <ul>
          <li>
            <Typography>
              Create a new file in the corresponding "docs" folder (the one in
              the where the roadmap.json file is located)
            </Typography>
          </li>
          <li>
            <Typography>
              Once this new page has been merged to master and processed by the
              pipeline it will be part of the documentation. After that it will
              appear in the mapping page as one of the selectable options.
            </Typography>
          </li>
        </ul>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          Create New Documentation Page
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{dialogContent()}</DialogContent>
        {/* <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Secondary action
          </Button>
          <Button color="primary" onClick={closeDialog}>
            Primary action
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};
