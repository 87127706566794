import {
  RELATION_PART_OF,
  RELATION_HAS_PART,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_PROVIDES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
} from '@backstage/catalog-model';
import { EntityHasApisCard } from '@backstage/plugin-api-docs';
import {
  EntityLayout,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
} from '@backstage/plugin-catalog';
import {
  EntityCatalogGraphCard,
  Direction,
} from '@backstage/plugin-catalog-graph';
import {
  isKubernetesAvailable,
  EntityKubernetesContent,
} from '@backstage/plugin-kubernetes';
import { EntityAnimalsDeployerCard } from '@efg/plugin-animals-deploy';
import { Grid } from '@material-ui/core';
import React from 'react';
import { FeatureFlagged } from '@backstage/core-app-api';
import { AboutCard as EntityAboutCard } from '../../pages/AboutCard';
import { EntityLinks } from '../EntityContent/EntityLinksContent';
import { entityWarningContent } from '../EntityContent/EntityWarningContent';
import { techdocsRoutableContent } from '../EntityContent/TechdocsContent';

export const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}

        <Grid item xs={12}>
          <EntityLinks />
        </Grid>

        <Grid item md={12}>
          <EntityAboutCard variant="gridItem" />
        </Grid>

        <FeatureFlagged with="use-version-api">
          <Grid item sm={12}>
            <EntityAnimalsDeployerCard />
          </Grid>
        </FeatureFlagged>

        <Grid item md={12}>
          <EntityCatalogGraphCard
            variant="gridItem"
            direction={Direction.TOP_BOTTOM}
            title="System Diagram"
            height={700}
            relations={[
              RELATION_PART_OF,
              RELATION_HAS_PART,
              RELATION_API_CONSUMED_BY,
              RELATION_API_PROVIDED_BY,
              RELATION_CONSUMES_API,
              RELATION_PROVIDES_API,
              RELATION_DEPENDENCY_OF,
              RELATION_DEPENDS_ON,
            ]}
            unidirectional={false}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    {techdocsRoutableContent()}

    <EntityLayout.Route path="/relations" title="Relations">
      <Grid container spacing={3}>
        <Grid item md={12}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
        <Grid item md={12} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route
      if={isKubernetesAvailable}
      path="/kubernetes"
      title="Kubernetes"
    >
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>
  </EntityLayout>
);
