import { createApiRef, FetchApi } from '@backstage/core-plugin-api';
import { Node, Edge } from 'reactflow';
import { CompoundEntityRef } from '@backstage/catalog-model';
import {
  TechDocsStorageApi,
  TechDocsApi,
} from '@backstage/plugin-techdocs-react';

import { TECHDOCS_ROADMAPS_ANNOTATION } from '../annotations';

export const roadmapStorageApiRef = createApiRef<RoadmapStorageApi>({
  id: 'plugin.roadmaps.service',
});

export interface RoadmapStorageApi {
  getRoadmap(entity: CompoundEntityRef, path: string): Promise<Roadmap>;
  getDocsMetadata(entity: CompoundEntityRef): Promise<DocsMetadata>;
}

export interface Roadmap {
  nodes: Node[];
  edges: Edge[];
}

export type DocsMetadata = {
  site_name: string;
  site_description: string;
  files?: string[];
};

export class RoadmapStorageApiClient implements RoadmapStorageApi {
  public techdocsStorageApi: TechDocsStorageApi;
  public techdocApi: TechDocsApi;
  private fetchApi: FetchApi;

  constructor(options: {
    techdocsStorageApi: TechDocsStorageApi;
    techdocApi: TechDocsApi;
    fetchApi: FetchApi;
  }) {
    this.techdocsStorageApi = options.techdocsStorageApi;
    this.techdocApi = options.techdocApi;
    this.fetchApi = options.fetchApi;
  }

  /**
   * Handles errors when talking to the backend plugin
   */
  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      const payload = await response.text();
      let message;
      switch (response.status) {
        case 404:
          message = `Could not find the Golden Path or Roadmap for this entity: \n
              1. Make sure the documentation has been rendered once (visit the doc tab). \n
              2. Make sure the path defined by ${TECHDOCS_ROADMAPS_ANNOTATION} points to the right file. \n
              3. Make sure that the plugin has been fully installed. \n
            `;
          break;
        default:
          message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      }
      throw new Error(message);
    }

    return await response.json();
  }

  async getRoadmap(entity: CompoundEntityRef, path: string): Promise<Roadmap> {
    const storageUrl = await this.techdocsStorageApi.getStorageUrl();
    const url = `${storageUrl}/${entity.namespace}/${entity.kind}/${entity.name}/${path}`;

    const response = await this.fetchApi.fetch(url);

    return await this.handleResponse(response);
  }

  // for some reason the techdoc metadata api is removing some of the data
  async getDocsMetadata(entity: CompoundEntityRef): Promise<DocsMetadata> {
    const apiOrigin = await this.techdocApi.getApiOrigin();
    const url = `${apiOrigin}/metadata/techdocs/${entity.namespace}/${entity.kind}/${entity.name}`;

    const response = await this.fetchApi.fetch(url);

    return await this.handleResponse(response);
  }
}
