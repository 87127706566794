import { Entity } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  LinkButton,
  ItemCardGrid,
  ItemCardHeader,
  Progress,
  Content,
  ContentHeader,
} from '@backstage/core-components';
import { Card, CardActions, CardContent, CardMedia } from '@material-ui/core';
import React from 'react';
import { useEntityList } from '@backstage/plugin-catalog-react';
import Alert from '@material-ui/lab/Alert';
import { entityRoadmapViewerRouteRef } from '../../routes';

/**
 * Props for {@link DocsCardGrid}
 *
 * @public
 */
export type RoadmapCardGridProps = {
  entities?: Entity[];
};

/**
 * Component which accepts a list of entities and renders an item card for each entity
 *
 * @public
 */
export const RoadmapCardGrid = (props: RoadmapCardGridProps) => {
  const getRouteToViewerPageFor = useRouteRef(entityRoadmapViewerRouteRef);

  if (!props.entities?.length) {
    return <ItemCardGrid data-testid="docs-explore" />;
  }

  return (
    <ItemCardGrid data-testid="docs-explore">
      {props.entities.map((entity: Entity, index: number) => (
        <Card key={index}>
          <CardMedia>
            <ItemCardHeader
              title={entity.metadata.title ?? entity.metadata.name}
            />
          </CardMedia>
          <CardContent>{entity.metadata.description}</CardContent>
          <CardActions>
            <LinkButton
              to={`${getRouteToViewerPageFor({
                kind: entity.kind,
                name: entity.metadata.name,
                namespace: entity.metadata.namespace ?? 'default',
              })}`}
              color="primary"
              data-testid="read_docs"
            >
              Take Me There
            </LinkButton>
          </CardActions>
        </Card>
      ))}
    </ItemCardGrid>
  );
};

export const RoadmapEntityList = (props: RoadmapCardGridProps) => {
  const entities = useEntityList();

  if (entities.loading) {
    return <Progress />;
  } else if (entities.error) {
    return <Alert severity="error">{entities.error.message}</Alert>;
  }

  if (props.entities !== undefined) {
    entities.entities.push(...props.entities);
  }

  return (
    <Content>
      <ContentHeader title="Roadmaps & GoldenPaths" />
      <RoadmapCardGrid entities={entities.entities} />
    </Content>
  );
};
