import { create } from 'zustand';
import React from 'react';
import { Node } from 'reactflow';

export type NodeData = {
  label: string;
  docPath: string;
};

export type DocViewerState = {
  open: boolean;
  node: Node<NodeData>;
  openDrawer: (event: React.MouseEvent, node: Node) => void;
  closeDrawer: () => void;
};

const useDocViewerStore = create<DocViewerState>(set => ({
  open: false,
  node: {
    id: '0',
    data: { label: 'not ready', docPath: 'roadmap' },
    position: { x: 0, y: 0 },
  },
  openDrawer: (_event: React.MouseEvent, node: Node) =>
    set({
      open: true,
      node: node,
    }),
  closeDrawer: () => set({ open: false }),
}));

export { useDocViewerStore };
