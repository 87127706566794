import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { useDialogStore, useRFStore, DialogState, RFState } from './store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

export default {
  title: 'Layout/Dialog',
  component: Dialog,
};

const selectorDialog = (state: DialogState) => ({
  open: state.open,
  node: state.node,
  setNode: state.setNode,
  openDialog: state.openDialog,
  closeDialog: state.closeDialog,
});

const selector = (state: RFState) => ({
  updateNodeLabel: state.updateNodeLabel,
  updateNodeType: state.updateNodeType,
  updateNodeDocPath: state.updateNodeDocPath,
  updateNodeRecommend: state.updateNodeRecommend,
  deleteNode: state.deleteNode,
});

export const NodeEditor = () => {
  const classes = useStyles();

  const { open, node, closeDialog } = useDialogStore(selectorDialog);

  const {
    updateNodeLabel,
    updateNodeType,
    updateNodeDocPath,
    updateNodeRecommend,
    deleteNode,
  } = useRFStore(selector);

  const types = ['primary', 'secondary'];

  const handleNodeTypeChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    const type = event.target.value as string;
    updateNodeType(node.id, type);
    node.type = type;
  };

  const handleNodeLabelChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const label = event.target.value as string;
    updateNodeLabel(node.id, label);
    node.data.label = label;
  };

  const handleNodeDocPathChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const docPath = event.target.value as string;
    updateNodeDocPath(node.id, docPath);
    node.data.docPath = docPath;
  };

  const handleNodeRecommendChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateNodeRecommend(node.id, checked);
    node.data.recommend = checked;
  };

  const dialogContent = () => {
    return (
      <>
        <FormControl fullWidth>
          <InputLabel id="env-label">Type</InputLabel>
          <Select
            label="Type"
            labelId="env-label"
            id="env"
            value={node.type}
            onChange={handleNodeTypeChange}
            fullWidth
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {types.map(t => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
          <TextField
            margin="dense"
            id="label"
            label="Name"
            variant="standard"
            autoComplete="off"
            fullWidth
            value={node.data.label}
            onChange={handleNodeLabelChange}
          />
          <TextField
            margin="dense"
            id="doc"
            label="Doc"
            variant="standard"
            autoComplete="off"
            fullWidth
            value={node.data.docPath}
            onChange={handleNodeDocPathChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={node.data.recommend}
                onChange={handleNodeRecommendChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Recommended"
          />
        </FormControl>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          Edit Node
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{dialogContent()}</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={_ => {
              deleteNode(node.id);
              closeDialog();
            }}
          >
            Delete
          </Button>
          <Button color="secondary" variant="outlined" onClick={closeDialog}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
