import React from 'react';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ExpandableNavigation,
  LightBox,
  ReportIssue,
} from '@backstage/plugin-techdocs-module-addons-contrib';

import { Entity } from '@backstage/catalog-model';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EntityLayout } from '@backstage/plugin-catalog';
import {
  isRoadmapAnnotationAvailable,
  RoadmapViewerComponent,
} from '@efg/plugin-techdoc-goldenpath-and-roadmaps';

export type TechdocsRoutableContentProps = {
  if?: (entity: Entity) => boolean;
};

const shouldDisplayDoc = (props?: TechdocsRoutableContentProps) => {
  return (entity: Entity): boolean => {
    if (props && props.if) {
      return props.if(entity) && !isRoadmapAnnotationAvailable(entity);
    }

    return !isRoadmapAnnotationAvailable(entity);
  };
};

/*
 * There is multiple case we need to handle
 * 1. We display the doc no matter what, so user get the instruction on how to add one
 * 2. We do not display the doc if isTechDocsAvailable is false
 * 3. We display a learning path instead of the doc
 *
 * The learning path is an alternative documentation view, if it exists it should replace the doc page on an entity
 * meaning you are still able to read the dedicated documentation reader page.
 */
export const techdocsRoutableContent = (
  props?: TechdocsRoutableContentProps,
) => {
  return (
    <>
      <EntityLayout.Route
        if={shouldDisplayDoc(props)}
        path="/docs"
        title="Docs"
      >
        <EntityTechdocsContent>
          <TechDocsAddons>
            <ReportIssue />
            <ExpandableNavigation />
            <LightBox />
          </TechDocsAddons>
        </EntityTechdocsContent>
      </EntityLayout.Route>

      <EntityLayout.Route
        if={isRoadmapAnnotationAvailable}
        path="/learning-path"
        title="Learning Path"
      >
        <RoadmapViewerComponent />
      </EntityLayout.Route>
    </>
  );
};
