import { EntityConsumedApisCard } from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasSubcomponentsCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  EntityKubernetesContent,
  isKubernetesAvailable,
} from '@backstage/plugin-kubernetes';
import { EntityGitlabContent } from '@immobiliarelabs/backstage-plugin-gitlab';
import { Grid } from '@material-ui/core';
import React from 'react';
import { CicdContent } from '../../../EntityContent/CicdContent';
import { overviewContent } from '../../../EntityContent/DefaultOverviewContent';
import { techdocsRoutableContent } from '../../../EntityContent/TechdocsContent';
import { entityRoutes } from '../../../EntityNavDropdown/EntityDropdownRoutes';

export const WebsiteEntityPage = () => {
  const { entity } = useEntity();
  const hasSubcomponents = entity?.relations?.some(
    relation => relation.type === 'hasPart',
  );

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route>

      {techdocsRoutableContent()}

      <EntityLayout.Route
        if={isKubernetesAvailable}
        path="/kubernetes"
        title="Kubernetes"
      >
        <EntityKubernetesContent refreshIntervalMs={30000} />
      </EntityLayout.Route>

      {entityRoutes([
        {
          path: '/ci-cd',
          title: 'CI/CD',
          content: <CicdContent />,
        },
        {
          path: '/repo-info',
          title: 'Repo Info',
          content: <EntityGitlabContent />,
        },
        {
          path: '/api',
          title: 'APIs',
          content: (
            <Grid container spacing={3} alignItems="stretch">
              {/* websites do not provide APIs so it has been removed*/}
              <Grid item md={12}>
                <EntityConsumedApisCard />
              </Grid>
            </Grid>
          ),
        },
        {
          path: '/dependencies',
          title: 'Dependencies',
          content: (
            <Grid container spacing={3} alignItems="stretch">
              <Grid item md={6}>
                <EntityDependsOnComponentsCard variant="gridItem" />
              </Grid>
              <Grid item md={6}>
                <EntityDependsOnResourcesCard variant="gridItem" />
              </Grid>
            </Grid>
          ),
        },
        {
          path: '/relations',
          title: 'Relations',
          content: (
            <>
              <Grid item md={12} xs={12}>
                <EntityCatalogGraphCard variant="gridItem" height={300} />
              </Grid>
              {hasSubcomponents && (
                <Grid item md={8} xs={12}>
                  <EntityHasSubcomponentsCard variant="gridItem" />
                </Grid>
              )}
            </>
          ),
        },
      ])}
    </EntityLayout>
  );
};
