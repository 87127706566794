import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import Alert from '@material-ui/lab/Alert';

import { useRouteRefParams, useApi } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  Page,
  Header,
  HeaderLabel,
  Progress,
  Content,
} from '@backstage/core-components';

import { entityRoadmapEditorRouteRef } from '../../routes';
import { RoadmapEditorComponent } from './RoadmapEditorComponent';
import { TECHDOCS_ROADMAPS_ANNOTATION } from '../../annotations';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  {
    content: {
      padding: 0,
    },
  },
  { name: 'RoadmapEditorPage' },
);

/*
 * RoadmapEditorPage load an existing Roadmap and allows you to edit it via a visual editor
 */
export const RoadmapEditorPage = () => {
  const classes = useStyles();
  const { kind, name, namespace } = useRouteRefParams(
    entityRoadmapEditorRouteRef,
  );
  const catalogApi = useApi(catalogApiRef);

  // Load the entity from the uri path
  const entity = useAsync(async (): Promise<Entity | undefined> => {
    return await catalogApi.getEntityByRef({ kind, name, namespace });
  }, [kind, name, namespace]);

  if (entity.loading) {
    return <Progress />;
  } else if (entity.error) {
    return <Alert severity="error">{entity.error.message}</Alert>;
  } else if (entity.value === undefined) {
    return (
      <Alert severity="error">
        Entity {kind}:{namespace}:{name} does not exist
      </Alert>
    );
  }

  return (
    <Page themeId="documentation">
      <Header
        title="RoadMap Editor"
        subtitle="Edit, preview, and bind documentation to Roadmaps or Golden Paths"
      >
        <HeaderLabel label="Name" value={entity.value.metadata.name} />
        <HeaderLabel
          label="Roadmap Path"
          value={
            entity.value.metadata.annotations![TECHDOCS_ROADMAPS_ANNOTATION]
          }
        />
      </Header>
      <Content className={classes.content}>
        <RoadmapEditorComponent entity={entity.value} />
      </Content>
    </Page>
  );
};
