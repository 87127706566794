import {
  CATALOG_FILTER_EXISTS,
  DefaultEntityFilters,
  EntityFilter,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { TECHDOCS_ROADMAPS_ANNOTATION } from '../../annotations';
import { useEffect } from 'react';

class RoadmapEntityFilter implements EntityFilter {
  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    return {
      [`metadata.annotations.${TECHDOCS_ROADMAPS_ANNOTATION}`]:
        CATALOG_FILTER_EXISTS,
    };
  }
}

type CustomFilters = DefaultEntityFilters & {
  roadmap?: RoadmapEntityFilter;
};

/**
 * Component responsible for updating TechDocs filters
 *
 * @public
 */
export const RoadmapEntityPicker = () => {
  const { updateFilters } = useEntityList<CustomFilters>();

  useEffect(() => {
    updateFilters({
      roadmap: new RoadmapEntityFilter(),
    });
  }, [updateFilters]);

  return null;
};
