import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import { useEffect } from 'react';

/*
 * HideSideBarTechDocsAddon used CSS styling not to display the left sidebar
 * The Roadmap should be used for navigation instead, that way we have move space to render to dos in the drawer
 */
export const HideSideBarTechDocsAddon = () => {
  const sidebars = useShadowRootElements<HTMLDivElement>(['.md-sidebar']);
  const contents = useShadowRootElements<HTMLDivElement>(['.md-content']);
  const footers = useShadowRootElements<HTMLDivElement>(['.md-footer']);

  useEffect(() => {
    sidebars.forEach(s => {
      s.style.display = 'none';
    });

    contents.forEach(c => {
      c.style.maxWidth = '100%';
      c.style.marginLeft = '0';
    });

    footers.forEach(c => {
      c.style.backgroundColor = 'white';
    });
  }, [contents, sidebars, footers]);

  // Nothing to render directly, so we can just return null.
  return null;
};
