import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import { TECHDOCS_ROADMAPS_ANNOTATION } from '../../annotations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

export const CodeSavingActionDialog = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={openDialog}>
        Publish Changes
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          Publish Changes
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            A Roadmap provides a visual way to navigate documentation. It's
            built as an extension to the Backstage TechDocs plugin. As a result,
            to display one you need the following:
            <ul>
              <li>A Backstage Entity</li>
              <li>A Documentation for that Entity</li>
              <li>A Roadmap JSON definitions in you documentation folder</li>
              <li>
                The {TECHDOCS_ROADMAPS_ANNOTATION} annotation pointing at the
                the Roadmap JSON definitions
              </li>
            </ul>
          </Typography>
        </DialogContent>
        <DialogContent dividers>
          <DialogContentText>
            <Typography>
              Follow the instructions below to create or update a Roadmap:
            </Typography>
            <ul>
              <li>
                <Typography>
                  Save the Roadmap JSON description files in a local directory.
                  It can be obtained via the copy code tab
                </Typography>
              </li>
              <li>
                <Typography>
                  Create a pull request to a new or existing git repository
                </Typography>
              </li>
              <li>
                <Typography>
                  If the Roadmap already exists, the changes will be reflected
                  in the software catalog once the pull request gets merged
                </Typography>
              </li>
              <li>
                <Typography>
                  But if you are creating a new Roadmap, you need to attached it
                  to a new entity.
                  <ul>
                    <li>
                      Create a new roadmap.json file in an entity documentation
                      folder
                    </li>
                    <li>
                      Add the annotation {TECHDOCS_ROADMAPS_ANNOTATION} to your
                      entity to locate the path to your roadmap.json, usually
                      `docs/roadmap.json` if the file is at the root of the
                      documentation folder
                    </li>
                  </ul>
                </Typography>
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
