import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';

/**
 * Properties for {@link CreateRoadmapButton}
 *
 * @alpha
 */
export type CreateRoadmapButtonProps = {
  title: string;
} & Partial<Pick<LinkProps, 'to'>>;

/**
 * A button that helps users to create a new visual roadmap or golden path.
 */
export const CreateRoadmapButton = (props: CreateRoadmapButtonProps) => {
  const { title, to } = props;
  const isXSScreen = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('xs'),
  );

  if (!to) {
    return null;
  }

  return isXSScreen ? (
    <IconButton
      component={RouterLink}
      color="primary"
      title={title}
      size="small"
      to={to}
    >
      <AddCircleOutline />
    </IconButton>
  ) : (
    <Button component={RouterLink} variant="outlined" color="primary" to={to}>
      {title}
    </Button>
  );
};
