import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog';
import React from 'react';
import { defaultEntityPage } from './ComponentPageTypes/DefaultEntityPage';
import { ServiceEntityPage } from './ComponentPageTypes/ServiceEntityPage';
import { WebsiteEntityPage } from './ComponentPageTypes/WebsiteEntityPage';
import { DocumentationEntityPage } from './ComponentPageTypes/DocumentationEntityPage';

export const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType(['service', 'infra-service'])}>
      <ServiceEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      <WebsiteEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('documentation')}>
      <DocumentationEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
